<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu v-if="menuVisible" @scrollToSection="scrollToSection" @openSignInModal="openSignInModal" @openSignUpModal="openSignUpModal" :currencyValue="currencyValue" :currencyCode="currencyCode" @changeCurrency="changeCurrency"/>
        </transition>
        <div class="mob menu-icon" @click="toggleMenu" :class="{'active': menuVisible}">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class='header-container'>
          <Header @scrollToSection="scrollToSection" @openSignInModal="openSignInModal" @openSignUpModal="openSignUpModal" :currencyValue="currencyValue" :currencyCode="currencyCode" @changeCurrency="changeCurrency"/>
        </div>
        <router-view @setNewError="setNewError" @chooseCategory="chooseCategory" @getProductPageContent="getProductPageContent" @editPrompt="editPrompt" :promptHistory="promptHistory" :order-history="orderHistory" @formatDate="formatDate"  :currency="currency"  @goToProduct="goToProduct" :productPageItem="productPageItem" @openBuyModal="openBuyModal"/>
        <div class='footer-container'>
          <Footer @scrollToSection="scrollToSection" />
        </div>
        <transition name="fade">
          <SignInModal v-if="signInModalIsVisible" @closeSignInModal="closeSignInModal" @openSignUpModal="openSignUpModal" @login="login" @forgotPass="forgotPass"/>
        </transition>
         <transition name="fade">
          <SignUpModal v-if="signUpModalIsVisible" @closeSignUpModal="closeSignUpModal" @openSignInModal="openSignInModal" @registration="registration" />
        </transition>
        <transition name="fade">
          <ContactModal v-if="contactModalIsVisible" @closeContactModal="closeContactModal" @contactSubmit="contactSubmit" />
        </transition>
        <transition name="fade">
          <TopUpModal v-if="topUpModalVisible" @withdrawSubmit="withdrawSubmit" @topUpSubmit="topUpSubmit"/>
        </transition>
        <transition name="fade">
          <ReadyModal v-if="readyModalVisible"/>
        </transition>
        <transition name="fade">
          <BuyModal v-if="buyModalVisible" @removeFromCart="removeFromCart" :cartContents="cartContents" :totalPrice="totalSum"/>
        </transition>
        <transition name="fade">
          <PromtCreate v-if="promptCreateModalIsVisible" />
        </transition>
        <transition name="fade">
          <div @click="newError = ''" class="modal-error title small" v-if="newError">{{ newError }}</div>
        </transition>
        <div 
          v-if="cookesModalIsVisible"
          class="modal-overlay" 
        >
          <div class="cookies-modal">
            <div class="title">
              We use cookies
            </div>
            <div class="desc">
              This website uses cookies to ensure you get the best experience.
            </div>
            <button 
              class="button" 
              @click="acceptCookies"
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {scroller} from 'vue-scrollto/src/scrollTo'
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
import SignInModal from './components/SignInModal.vue'
import SignUpModal from './components/SignUpModal.vue'
import ContactModal from './components/ContactModal.vue'
import TopUpModal from './components/TopUpModal.vue'
import ReadyModal from './components/ReadyModal.vue'
import BuyModal from './components/BuyModal.vue'
import PromtCreate from './components/PromtCreate.vue'
import {loadScriptsFromString} from "@/utils/load_scripts";


export default {
  name: 'app',
  components: {
    Header,
    Menu,
    Footer,
    SignInModal,
    SignUpModal,
    ContactModal,
    TopUpModal,
    ReadyModal,
    BuyModal,
    PromtCreate
  },
  data: function() {
    return {
     pageLoaded: false,
     menuVisible: false,
     signInModalIsVisible: false,
     signUpModalIsVisible: false,
     contactModalIsVisible: false,
      isAuth: false,
      error: '',
      successMessage: '',
      orderHistory: [],
      promptHistory: [],
      depositHistory: [],
      createdHistory: [],
      totalSum: '0.00',
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      productPageItem: null,
      imgDomain: '',
      textPageList: '',
      currency: '',
      currencyValue: '',
      currencyCode: '',
      currencyOptions: [],
      hostname: '',
     topUpModalVisible: false,
     readyModalVisible: false,
     readyPromt: false,
     buyModalVisible: false,
     userBalance: '0.00',
     mainProducts: [],
     avatar_path: '',
     cartContents: [],
     editData: null,
     activeCategory: '',
     promptCreateModalIsVisible: false,
     topUpModalAction: '',
     newError: '',
     settings: '',
     profileData: '',
     cookesModalIsVisible: ''
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      if (this.isAuth) {
        this.getPromptHistory();
        this.getOrderHistory();
        this.getDepositHistory();
        this.getCreatedHistory();
      }
    }
  },
  methods: {
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');

      if (!cookiesAccepted) {
          this.cookesModalIsVisible = true;
      }
    },
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.cookesModalIsVisible = false;
    },
    setNewError(error) {
      let self = this;
      this.newError = error;
      setTimeout(()=> {
        self.newError = '';
      }, 2500)
    },
    getCurrencies() {
      return this.$http.get(process.env.VUE_APP_API + 'currencies')
      .then((res) => {
          const that = this;
          
          this.currencyOptions = res.data.payload;

          if (localStorage.getItem("currency")) {
            this.currencyCode = localStorage.getItem("currency");

            const currency = res.data.payload.find(({code}) => code === that.currencyCode);

            if (currency) {
              this.currencyValue = currency.value;
              this.currency = currency.symbol;
            }
          } else {
            this.currencyCode = this.currencyOptions[0].code;
            this.currency = this.currencyOptions[0].symbol;
            this.currencyValue = this.currencyOptions[0].value;
          }
      })
      .catch(() => {
       
      })
    },
    changeCurrency(item) {
      this.currencyCode = item;
      this.checkCurrency();
    },
    checkCurrency() {
      const that = this;
      const currency = this.currencyOptions.find(({code}) => code === that.currencyCode);

      if (currency) {
       
        this.currency = currency.symbol;
        this.currencyValue = currency.value;
        console.log(currency.symbol)
        if (this.isAuth) {
          this.getDepositHistory();
          this.getCartContents();
          this.getProfileData();
          this.getPromptHistory();
          this.getOrderHistory();
          this.getCreatedHistory();
        }
      }
    },
    chooseCategory(item) {
      this.activeCategory = item;
      this.$router.push({path: '/product-list'});
    },
    scrollToSection(section) {
      if (this.$route.name != 'Home') {
        this.$router.push({path: '/'});
      }
      var options = {
          offset: -100
      }

      setTimeout(()=> {
        const firstScrollTo = scroller()
        firstScrollTo('#' + section, 1000, options)
      }, 1000)
    },
    crealCart() {
      this.$http.post(process.env.VUE_APP_API + 'cart/clear')
      .then((res) => {
        console.log(res)
        this.cartContents = [];
        this.totalSum = 0.00
      })
      .catch((res) => {
        console.log(res);
      })
    },
    removeFromCart(item) {
      let param = {
        'id' : item.item_id
      } 
      this.$http.post(process.env.VUE_APP_API + 'cart/remove', param)
      .then((res) => {
        this.cartContents = res.data.cart.payload;
        this.totalSum = res.data.cart.totalAmount;
      })
      .catch((res) => {
        console.log(res);
      })
    },
    addToCart(item) {
      let param = {
        'id': item.id,
        'count': 1
      }
      this.$http.post(process.env.VUE_APP_API + 'cart/add', param)
      .then((res) => {
        this.cartContents = res.data.cart.payload;
        this.totalSum = res.data.cart.totalAmount
      })
      .catch((res) => {
        console.log(res);
      })
    },
    getCartContents() {
      this.$http.get(process.env.VUE_APP_API + 'cart/contents')
      .then((res) => {
        this.cartContents = res.data.cart.payload;
        this.totalSum = res.data.cart.totalAmount
      })
      .catch((res) => {
        console.log(res);
      })
    },
    goToPromtCreate() {
      this.promptCreateModalIsVisible = true;
    },
    closePromptCreateModal() {
      this.promptCreateModalIsVisible = false;
    },
    withdrawSubmit(data) {
      this.$http.post(process.env.VUE_APP_API + 'withdraw/request', data)
      .then((res) => {
        if (res.data.status == "OK") {
          let self = this;
          self.successMessage = res.data.message;
          setTimeout(function() {
            self.successMessage = "";
            self.closeTopUpModal();
            self.getProfileData();
          }, 3000)
        } else {
          this.error = res.data.message;
        }
      })
      .catch((res) => {
        if (res.response.data.errors) {
            let firstKey = Object.keys(res.response.data.errors)[0];
            if (typeof res.response.data.errors[firstKey] !== 'undefined') {
                this.error = res.response.data.errors[firstKey][0]
            } else {
                this.error = res.response.data.errors[0]
            }
        } else {
          this.error = res.response.data.message
        }
        console.log(res)
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    topUpSubmit(data) {
      this.$http.post(process.env.VUE_APP_API + 'deposit/init', data)
      .then((res) => {
        if (res.data.status == "OK") {
          window.location = res.data.redirect_url;
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((res) => {
        if (res.response.data.errors) {
          this.error = res.response.data.errors[0]
        } else {
          this.error = res.response.data.message;
        }
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
     buyProduct() {
      this.$http.post(process.env.VUE_APP_API + 'cart/purchase')
      .then((res) => {
        if (res.data.status == "OK") {
          if (res.data.redirect_url) {
            window.location = res.data.redirect_url;
          } else {
            this.successMessage =  'Success';
            this.getProfileData();
            this.getCartContents();
            this.closeBuyModal();
            this.$router.push({path: '/profile/purchased-products'});
          }
        } else {
          if (res.data.topUp) {
            this.setNewError(res.data.message);
            this.closeBuyModal();
            this.$router.push({path: '/profile/main'});
            this.openTopUpModal('deposit');
          } else {
            this.error = res.data.message;
          }
        }
      })
      .catch((res) => {
        if (res.response.data.topUp) {
          this.setNewError(res.response.data.message);
          this.closeBuyModal();
          this.$router.push({path: '/profile/main'});
          this.openTopUpModal('deposit');
        } else {
          this.error = res.response.data.message;
        }
      })
    },
    openReadyModal() {
      this.readyModalVisible = true;
    },
    openBuyModal(item) {
      this.closeMenu();
      if (this.isAuth) {
        if (item) {
          this.addToCart(item);
        }
        this.buyModalVisible = true;
      } else {
        this.openSignInModal();
      }
      
    },
    closeBuyModal() {
      this.buyModalVisible = false;
    },
    setReadyPromt() {
      this.readyPromt = true;
    },
    closeReadyModal() {
      this.readyPromt = false;
      this.successMessage = '';
      this.error = '';
      this.readyModalVisible = false;
    },
    openTopUpModal(action) {
      this.topUpModalAction = action;
      this.topUpModalVisible = true;
    },
    closeTopUpModal() {
      this.topUpModalVisible = false;
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    openSignInModal() {
      this.clearError();
      this.signUpModalIsVisible = false;
      this.signInModalIsVisible = true;
    },
    openSignUpModal() {
      this.clearError();
      this.signInModalIsVisible = false;
      this.signUpModalIsVisible = true;
    },
    clearError() {
      this.error = '';
    },
    closeSignUpModal() {
      this.signUpModalIsVisible = false;
      this.clearError();
    },
    closeSignInModal() {
      this.signInModalIsVisible = false;
      this.clearError();
    },
    openContactModal() {
      this.contactModalIsVisible = true;
    },
    closeContactModal() {
      this.contactModalIsVisible = false;
      this.clearError();
    },
    login(data) {
      this.$http.post(process.env.VUE_APP_API + 'login', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.isAuth = true;
          this.closeSignInModal();
          this.getPromptHistory();
          this.getOrderHistory();
          this.getDepositHistory();
          this.getCreatedHistory();
          this.getProfileData()
          this.getCartContents();
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
          this.error = res.response.data.message;
      })
    },
    forgotPass(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'user/reset-password', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage = 'Success';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
            self.closeSignInModal();
          }, 2000)
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((error) => {
        this.error = error.response.data.message
      })
    },
    contactSubmit(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'contact-us', data)
      .then((res) => {
        if (res.data.status == "OK") {
          self.clearError();
          self.successMessage = 'Thank you!'
          self.setTimeout(function() {
            self.successMessage = '';
            self.closeContactModal();
          }, 3000)
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
          this.error = res.response.data.message;
      })
    },
    registration(data) {
      let self = this;
      if (data.password !== data.passConfirm) {
        this.error = 'Password confirmation failed!';
      } else {
        this.$http.post(process.env.VUE_APP_API + 'register', data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.clearError();
            self.isAuth = true;
            self.closeSignUpModal();
            this.getProfileData();
          } else {
            this.error = res.data.message
          }
        })
        .catch((res) => {
          if (res.response.data.errors.email) {
            this.error = res.response.data.errors.email[0];
          } else if (res.response.data.message) {
            this.error = res.response.data.message;
          }
        })
      }
    },
    logout() {
      this.$http.post(process.env.VUE_APP_API + 'logout')
      .then(() => {
        if (this.$route.name != 'Home') {
          this.$router.push({ path: '/' })
        }
        this.isAuth = false;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getPromptHistory() {
      this.$http.get(process.env.VUE_APP_API + 'user/media/purchased')
      .then((res) => {
        this.promptHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getOrderHistory() {
      this.$http.get(process.env.VUE_APP_API + 'purchase/history')
      .then((res) => {
        this.orderHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getDepositHistory() {
      this.$http.get(process.env.VUE_APP_API + 'deposit/history')
      .then((res) => {
        this.depositHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getCreatedHistory() {
      this.$http.get(process.env.VUE_APP_API + 'user/media/created')
      .then((res) => {
        this.createdHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getProfileData() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        this.userBalance = res.data.balance;
        this.avatar_path = res.data.avatar_path;
        this.profileData = res.data;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
          day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
          month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    goToProduct(item) {
      this.$router.push({path: '/products/' + item.id, params: {data: item.id}});
      this.getProductPageContent(item.id);
    },
    getProductPageContent(id) {
      this.productPageItem = '';
      this.$http.get(process.env.VUE_APP_API + 'products/' + id + '/info')
      .then((res) => {
        this.productPageItem = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    goToTerms() {
      let pageId = '';
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_terms) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + pageId, params: {data: pageId}})
          this.getTextPageContent(pageId);  
          this.closeSignUpModal();
          this.closeTopUpModal();
        } 
      }
    },
    goToPrivacy() {
      let pageId = '';
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_privacy) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + pageId, params: {data: pageId}})
          this.getTextPageContent(pageId);
          this.closeSignUpModal();
          this.closeTopUpModal();
        } 
      }
    },
    goToPage(item) {
      let newItem;
      if (item == 'privacy') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('privacy')) {
            newItem = this.textPageList[i].id;
          } 
        }
      } else if (item == 'terms') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('terms')) {
            newItem = this.textPageList[i].id;
          } 
        }
      } else {
        newItem = item
      }
      this.$router.push({path: '/pages/' + newItem, params: {data: newItem}})
      this.getTextPageContent(newItem);
      this.closeSignUpModal();
    },
    getTextPageContent(id) {
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';
      this.$http.get(process.env.VUE_APP_API + 'static-pages/' + id)
      .then((res) => {
        this.textPageTitle = res.data.payload.title;
        this.textPageContent = res.data.payload.content;
        this.textPageHeader = res.data.payload.header;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch(() => {
         
      })
    },
    isElementVisible(element) {
      const computedStyle = window.getComputedStyle(element);
      const isVisible = 
        computedStyle.display !== 'none' &&
        computedStyle.visibility !== 'hidden' &&
        parseInt(computedStyle.width, 10) > 0 &&
        parseInt(computedStyle.height, 10) > 0;

      return isVisible;
    },
    editPrompt(item) {
      this.editData = item;
      this.goToPromtCreate();
    },
    clearEditData() {
      this.editData = null;
    },
    getGoogleTagManagerSnippet() {
      this.$http.get(process.env.VUE_APP_API + 'settings')
      .then((res) => {
          this.settings = res.data.payload;
          const snippet = res.data.payload.analytics_js;
          if (snippet) {
            loadScriptsFromString(snippet);
          }
      })
      .catch(() => {
         
      })
      
    },
  },
  mounted: function() {
    this.cookiesModal();
    this.hostname = this.getDomainName(window.location.hostname)
    this.currency = process.env.VUE_APP_CURRENCY;
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.pageLoaded = true;
    let self = this;
    this.getCurrencies();
    window.addEventListener("resize", self.menuHeight);
    self.menuHeight();
    self.getTextPages();
    
    self.getGoogleTagManagerSnippet();
    
    self.$http.get(process.env.VUE_APP_API + 'is-auth')
    .then((res) => {
        console.log('auth: ' + res.data.authenticated);
        self.isAuth = res.data.authenticated

        if (res.data.authenticated) {
          self.getPromptHistory();
          self.getOrderHistory();
          self.getDepositHistory();
          self.getCreatedHistory();
          self.getProfileData();
          self.getCartContents();
        }
    })
    .catch(() => {
        
    })

  },
  computed: {
    
  }
}
</script>
